import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Container, Row, Col } from 'react-bootstrap'
import PageWrapper from '../components/PageWrapper'
import MetaData from '../components/MetaData'
import { Section, Title, Text } from '../components/Core'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import FAQS from '../sections/support/supportFaqs'
import Hero from '../sections/common/Hero'
import styled from 'styled-components'

const StyledForm = styled.div`
  
  .hs-form-field {
    color: ${props => (props.userDarkMode ? '#FAFAFA' : '#101010')};
    margin-bottom: 16px;
  }
  .hs-input {
    width: 100%;
    min-height: 44px;
    padding: 0.50rem 0.85rem;
    border-radius: 6px;
    background: ${props => (props.userDarkMode ? 'rgba(255, 255, 255, 0.1) !important' : 'rgba(0, 0, 0, 0.1) !important')}; 
    border-color: transparent;
    margin-bottom: 8px;
  }
  .hs-input option {
    padding: 0.85rem 0.85rem;
    background: ${props => (props.userDarkMode ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)')}; 
    border-color: transparent;
  }
  .hs-button {
    padding: 0.85rem 1.75rem;
    border-radius: 6px;
    background: #3377C6;
    border-color: transparent;
    margin-top: 8px;
    color: white;
  }
  #lc_message-33ed784f-9454-4b6d-9b1a-7a27f6f0bbab {
    height: 100px;
    color: ${props => (props.userDarkMode ? '#FAFAFA' : '#101010')}; 
  }
  #lc_attachment-33ed784f-9454-4b6d-9b1a-7a27f6f0bbab {
    background: transparent;
  }
  .is-placeholder {
    color: ${props => (props.userDarkMode ? '#FAFAFA' : '#101010')}; 
  }
  .hs-error-msg {
    margin-top: 0px;
    color: ${props => (props.userDarkMode ? '#FB4C42' : '#D0433B')};
  }
  .submitted-message {
    color: ${props => (props.userDarkMode ? '#FAFAFA' : '#101010')}; 
  }
  .hs-error-msgs inputs-list {
    color: ${props => (props.userDarkMode ? '#FAFAFA' : '#101010')};
  }
  #lc_type_of_inquiry-33ed784f-9454-4b6d-9b1a-7a27f6f0bbab {
    color: ${props => (props.userDarkMode ? '#FAFAFA' : '#101010')}; 
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
  }
`

const Support = props => {
  const { t, ready } = useTranslation(['support'], { useSuspense: false })
  const userSettings = useSelector(state => state.user.user_settings)
  const userSessionExpired = useSelector(state => state.user.session_expired)
  const userDarkModeSelector = useSelector(state => state.user.darkMode)
  const userDarkMode = userSessionExpired
    ? userDarkModeSelector
    : typeof userSettings !== 'undefined'
    ? userSettings.dark_mode
    : false

  const [completed, setCompleted] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      setCompleted(true)
    }, 1000)
    return () => clearTimeout(timer)
  }, [])

  useEffect(() => {
    const head = document.querySelector('head');
    const script = document.createElement('script');
    script.setAttribute(
      'src',
      'https://assets.calendly.com/assets/external/widget.js'
    );
    head.appendChild(script);
  }, []);

  useEffect(() => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: "na1",
          portalId: "20618924",
          formId: "33ed784f-9454-4b6d-9b1a-7a27f6f0bbab",
          target: '#hubspotForm'
        })
      }
  }, [completed])

  return ready && (
    <>
      <MetaData page="support" />
      <Helmet>
        <script charset="utf-8" type="text/javascript" src="//js.hsforms.net/forms/v2-legacy.js"></script>
        <script charset="utf-8" type="text/javascript" src="//js.hsforms.net/forms/v2.js"></script>
        <script type="text/javascript" src="https://assets.calendly.com/assets/external/widget.js"></script>
      </Helmet>
      <PageWrapper headerDark footerDark>
        <Hero
          bg={userDarkMode ? 'black' : 'bg'}
          title={t('toHelp')}
        />
        <FAQS />
        <Section bg={userDarkMode ? 'black' : 'bg'}>
          <Container>
            <Row>
              <Col lg="5">
                <Title color={userDarkMode ? '#FAFAFA' : 'black'}>
                  {t('call')}
                </Title>
                <Text color={userDarkMode ? '#FAFAFA' : 'black'}>
                  {t('specialists')}
                </Text>
                <div
                  className="calendly-inline-widget"
                  data-url="https://calendly.com/d/djt-9jk-788/lightspeed-crypto-support-call"
                  style={{
                    text: 'Book now', 
                    color: '#0069ff', 
                    textColor: '#ffffff',
                    width: '90%',
                    height: '90%',
                    marginTop: '30px',
                    marginBottom: '30px'
                  }}
                />
              </Col>
              <Col lg="7" className="mt-5">
                <div style={{padding: '30px', borderRadius: '10px', backgroundColor: userDarkMode ? '#191B20' : '#FFFFFF'}}>
                  <Title color={userDarkMode ? '#FAFAFA' : 'black'}>
                    {t('general')}
                  </Title>
                  <Text variant="small" color={userDarkMode ? '#FAFAFA' : 'black'}>
                    {t('question')}
                  </Text>
                  <StyledForm className="mt-5" userDarkMode={userDarkMode} id="hubspotForm"></StyledForm>
                </div>
              </Col>
            </Row>            
          </Container>
        </Section>
      </PageWrapper>
    </>
  )
}

export default Support