import React from 'react'
import styled from 'styled-components'
import { Container, Row, Col, Nav, Tab } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'
import PageWrapper from '../../components/PageWrapper'
import { Section, Title, Text, Box } from '../../components/Core'
import { useSelector } from 'react-redux'
import NewTabIcon from '../../assets/image/svg/ic_newtab16.inline.svg'
import Link from 'gatsby-link'
import setLanguage from '../../helpers/languageConfig'

const NavStyled = styled(Nav)`
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background-color: ${props =>
      props.userdarkmode === 'true' ? 'black' : 'bg'};
    padding-top: 15px;
    padding-bottom: 15px;
    a {
      color: #3377C6 !important;
      font-size: 18px !important;
      padding: 8px 21px;
      &:hover {
      },
      &:active,
      &:visited,
      &.nav-link {
        &.active {
          color: ${props =>
            props.userdarkmode === 'true' ? 'white' : 'black'}  !important;
        }
      },
    }
  `

const SupportFaqs = exchCode => {
  const { t } = useTranslation('support', { useSuspense: false });
  const userSettings = useSelector(state => state.user.user_settings)
  const userSessionExpired = useSelector(state => state.user.session_expired)
  const userDarkModeSelector = useSelector(state => state.user.darkMode)
  const userDarkMode = userSessionExpired
    ? userDarkModeSelector
    : typeof userSettings !== 'undefined'
    ? userSettings.dark_mode
    : false
  const lang = setLanguage()

  return (
    <>
      <PageWrapper headerDark footerDark>
        <Section className="py-0" bg={userDarkMode ? 'black' : 'bg'}>
          <Container>
            <Row>
              <Col lg="12">
                <Title
                  color={userDarkMode ? 'light' : 'dark'}
                  mb={3}
                >
                  {t('checkFaqs')}
                </Title>
              </Col>
            </Row>
            <Box
                style={{
                  border: '1px solid',
                  borderColor: 'rgba(0, 0, 0, 0.1)',
                  borderRadius: 10,
                  backgroundColor: userDarkMode ? '#191B20' : 'white',
                  padding: '15px 15px 15px',
                }}
              >
              <Tab.Container id="left-tabs-example" defaultActiveKey="FAQ1">
                <Row>
                  <Col md="5" className="mb-5 mb-md-0">
                    <NavStyled
                      style={{ cursor: 'pointer' }}
                      userdarkmode={userDarkMode ? 'true' : 'false'}
                      className="flex-column "
                    >
                      {/* {faqKeysArray ? faqKeysArray.map((element, index) => {
                        console.log('INMAP', element);
                        return (
                          <Nav.Link key={index} eventKey={index}>{element}</Nav.Link>
                        );
                      }) : null} */}
                      <Nav.Link eventKey="FAQ1">
                        {t('resetPass')}
                      </Nav.Link>
                      <Nav.Link eventKey="FAQ2">
                        {t('fundAccount')}
                      </Nav.Link>
                      <Nav.Link eventKey="FAQ3">
                        {t('lsRouting')}
                      </Nav.Link>
                      <Nav.Link eventKey="FAQ4">
                        {t('submitDocs')}
                      </Nav.Link>
                      <Link to={`/${lang}/learning/faqs`}>
                        {t('moreFaqs')}
                        <NewTabIcon
                          style={{
                            filter: userDarkMode ? 'invert(40%)' : 'invert(50%)',
                            marginBottom: '2px',
                            marginLeft: '4px',
                            width: '16px',
                            height: '16px',
                          }}
                          aria-label={t('newTab')}
                          role="img"
                        />
                      </Link>
                    </NavStyled>
                  </Col>
                  <Col md="7">
                    <Tab.Content>
                      <Tab.Pane eventKey="FAQ1">
                        <Box>
                          <Box my={3}>
                            <Title
                              color={userDarkMode ? '#FAFAFA' : 'black'}
                              variant="card"
                              mb={3}
                              fontSize="24px"
                            >
                              {t('resetPass')}
                            </Title>
                            <Text
                              color={userDarkMode ? '#FAFAFA' : 'black'}
                            >
                              {t('forgotPass')}
                            </Text>
                          </Box>
                        </Box>
                      </Tab.Pane>
                      <Tab.Pane eventKey="FAQ2">
                        <Box>
                          <Box my={3}>
                            <Title
                              color={userDarkMode ? '#FAFAFA' : 'black'}
                              variant="card"
                              mb={3}
                              fontSize="24px"
                            >
                              {t('fundAccount')}
                            </Title>
                            <Text
                              color={userDarkMode ? '#FAFAFA' : 'black'}
                            >
                              {t('fundVia')}
                            </Text>
                          </Box>
                        </Box>
                      </Tab.Pane>
                      <Tab.Pane eventKey="FAQ3">
                        <Box>
                          <Box my={3}>
                            <Title
                              color={userDarkMode ? '#FAFAFA' : 'black'}
                              variant="card"
                              mb={3}
                              fontSize="24px"
                            >
                              {t('lsRouting')}
                            </Title>
                            <Text
                              color={userDarkMode ? '#FAFAFA' : 'black'}
                            >
                              {t('routing')}
                            </Text>
                          </Box>
                        </Box>
                      </Tab.Pane>
                      <Tab.Pane eventKey="FAQ4">
                        <Box>
                          <Box my={3}>
                            <Title
                              color={userDarkMode ? '#FAFAFA' : 'black'}
                              variant="card"
                              mb={3}
                              fontSize="24px"
                            >
                              {t('submitDocs')}
                            </Title>
                            <Text
                              color={userDarkMode ? '#FAFAFA' : 'black'}
                            >
                              {t('submitAccountDocs')}
                            </Text>
                          </Box>
                        </Box>
                      </Tab.Pane>
                      {/* <Tab.Pane eventKey="FAQ5">
                        <Box>
                          <Box mb={4}>
                            <Title
                              color={userDarkMode ? '#FAFAFA' : 'black'}
                              variant="card"
                              mb={3}
                              fontSize="24px"
                            >
                              {t('moreFaqs')}
                            </Title>
                            <Text
                              color={userDarkMode ? '#FAFAFA' : 'black'}
                              variant="small"
                            >
                              {t('resetPass')}
                            </Text>
                          </Box>
                        </Box>
                      </Tab.Pane> */}
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
            </Box>
          </Container>
        </Section>
      </PageWrapper>
    </>
  )
}
export default SupportFaqs
